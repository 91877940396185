<script setup>
import { WdsButtonLink } from '@wds/components';

// ### COMPOSABLES
const { $tracking } = useNuxtApp();
const { isDesktop } = useDevice();
const { isLargeScreen } = useMq();
const { useUrl } = useUrls();

const logoData = ref({
  asset: 'hw-orange',
  width: isLargeScreen.value ? '203' : '160',
  height: isLargeScreen.value ? '45' : '35',
});

watch(isLargeScreen, (newValue) => {
  const newIsLargeScreen = typeof newValue === 'object' ? newValue?.value : newValue;
  logoData.value.width = newIsLargeScreen ? '203' : '160';
  logoData.value.height = newIsLargeScreen ? '45' : '35';
});

// ### COMPUTED
const propertySignUpUrl = useUrl.getPropertySignUpUrl();

// ### METHODS
const redirectHostelSignup = function () {
  $tracking.onHostelSignupInteraction({ parameter1: 'new button' });
};
</script>

<template>
  <CommonHwNavBar
    class="homepage-navbar"
    :logo="logoData"
  >
    <template #nav-extra-content>
      <div class="list-your-property">
        <WdsButtonLink
          v-if="isDesktop && toValue(isLargeScreen)"
          class="list-your-property-button"
          :href="propertySignUpUrl"
          :text="$t('t_REGISTERPROPERTY')"
          icon-start="hostel"
          target="_blank"
          :reversed="!toValue(isLargeScreen)"
          @click="redirectHostelSignup"
        />
      </div>
    </template>
  </CommonHwNavBar>
  <HomepageHwHero class="homepage-hero" />
</template>

<style scoped lang="scss">
.homepage-navbar {
  padding: $wds-spacing-m;

  .list-your-property {
    display: flex;
    justify-content: flex-end;

    .list-your-property-button {
      cursor: pointer;
      text-decoration: none;
    }
  }
}

.homepage-hero {
  padding: 0 $wds-spacing-s;
}

@include tablet-large {
  .homepage-navbar {
    padding: $wds-spacing-s $wds-spacing-l;
  }

  .homepage-hero {
    padding: 0 $wds-spacing-l;
  }
}

@include desktop {
  .homepage-navbar {
    padding: $wds-spacing-m wds-rem(40px);
  }

  .homepage-hero {
    padding: 0 wds-rem(40px);
  }
}
</style>
