<script setup>
import { WdsSearchFormInline, WdsSearchFormStandalone, WdsSearchFormWizard } from '@wds/components';
import searchConfig from '~/config/search';

// ### STORE/PINIA
const searchStore = useSearchStore();

// ### COMPOSABLES
const dateTime = useDateTime();
const searchData = useSearch();

// ### COMPUTED
const searchFormInlineParams = computed(() => ({
  size: 'large',
  ...searchData.getSearchFormCommonParams.value,
}));

const searchFormStandaloneParams = computed(() => ({ translations: searchData.getSearchFormTranslations.value }));

const searchFormWizardParams = computed(() => ({
  activeStep: searchData.wizardActiveStep.value,
  openWizard: searchData.wizardIsOpen.value,
  focusDestinationOnOpen: true,
  ...searchData.getSearchFormCommonParams.value,
}));

// ### CREATED
searchStore.setSearchCheckIn(dateTime.tomorrow);
searchStore.setSearchCheckOut(dateTime.addDaysToDate(dateTime.tomorrow, searchConfig.initialNights));
</script>

<template>
  <WdsSearchFormInline
    class="hp-search-form-desktop"
    v-bind="searchFormInlineParams"
    @destination-selected="searchData?.handleDestinationFieldClicked"
    @destination-input="searchData?.handleDestinationSearch"
    @destination-item-selected="searchData?.handleDestinationItemSelected"
    @recent-destination-selected="searchData?.handleRecentDestinationItemSelected"
    @current-location="searchData?.handleCurrentLocation"
    @dates-selected="searchData?.handleDatesFieldClicked"
    @guests-selected="searchData?.handleGuestsFieldClicked"
    @guests-change="searchData?.handleGuestsNumberChanged"
    @guests-group-type-change="searchData?.handleGuestsGroupTypeChanged"
    @guests-group-ages-change="searchData?.handleGuestsGroupAgesChanged"
    @search-form-submit="searchData?.handleSubmit"
  />
  <WdsSearchFormStandalone
    class="hp-search-form-mobile"
    v-bind="searchFormStandaloneParams"
    @input-selected="searchData?.handleWizardOpen"
  />
  <WdsSearchFormWizard
    v-bind="searchFormWizardParams"
    @destination-selected="searchData?.handleDestinationItemSelected"
    @destination-input="searchData?.handleDestinationSearch"
    @destination-item-selected="searchData?.handleDestinationItemSelected"
    @recent-destination-selected="searchData?.handleRecentDestinationItemSelected"
    @current-location="searchData?.handleCurrentLocation"
    @guests-change="searchData?.handleGuestsNumberChanged"
    @guests-group-type-change="searchData?.handleGuestsGroupTypeChanged"
    @guests-group-ages-change="searchData?.handleGuestsGroupAgesChanged"
    @search-form-submit="searchData?.handleSubmit"
    @search-form-close="searchData?.handleWizardClose"
  />
</template>

<style scoped lang="scss">
.hp-search-form-desktop {
  display: none;
}

.hp-search-form-mobile {
  display: flex;
  flex: 1;
}

@include tablet-large {
  // LARGE TABLET RULES
  .hp-search-form-desktop {
    display: block;
  }

  .hp-search-form-mobile {
    display: none !important;
  }
}
</style>
