<script setup>
const props = defineProps({
  source: {
    type: String,
    default: () => '',
  },
});

const { $tracking } = useNuxtApp();

// ### TRACKING
function handleBannerClick() {
  $tracking.onBlackFridayBannerClick(props.source);
}

function handleTCClick() {
  $tracking.onBFTermsConditionsClick(props.source);
}

// @TODO - Check that BlackFriday page link is correct
</script>

<template>
  <NuxtLink
    class="bf-banner"
    target="_blank"
    to="https://www.hostelworld.com/deals/black-friday"
    @click="handleBannerClick"
  >
    <h1 class="bf-banner-title title-2-bld">
      Black Friday
    </h1>
    <div class="bf-banner-text-container body-2-bld">
      <p>
        {{ $t('t_BFLPHERO1') }}
        <NuxtLink
          class="link link-2-reg"
          target="_blank"
          to="https://www.hostelworld.com/deals/black-friday#terms-and-conditions"
          @click.stop="handleTCClick"
        >
          {{ $t('t_TCAPPLY') }}
        </NuxtLink>
      </p>
    </div>
  </NuxtLink>
</template>

<style lang="scss" scoped>
.bf-banner {
  display: flex;
  align-items: center;
  gap: $wds-spacing-m;
  padding: $wds-spacing-s $wds-spacing-m;
  border-radius: $wds-border-radius-m;
  background: linear-gradient(243deg, #ff714b 0%, #a4005d 20.78%, $wds-color-ink-darker 61.07%);
  text-decoration-line: none !important;
  container-type: inline-size;
  margin-bottom: $wds-spacing-m;

  .bf-banner-title {
    transform: rotate(-2deg);
    color: $wds-color-white;
    text-shadow: wds-rem(-2px) wds-rem(2px) 0 $wds-color-pink;
    padding: 0.2rem 0;

    @include tablet {
      white-space: nowrap;
    }
  }

  .bf-banner-text-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $wds-color-white;
    gap: $wds-spacing-s;

    .link {
      color: $wds-color-white;
      text-decoration-line: underline;
    }
  }
}
</style>
